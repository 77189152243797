import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  LineElement,
  LogarithmicScale,
  PointElement,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  LogarithmicScale,
  ArcElement
);

const LineChart = (props) => {
  const { options } = props;
  // console.log(options, "options");
  return (
    <div>
      <Line
        data={props.data}
        options={{
          ...props.options,
          interaction: {
            mode: "index",
            intersect: false,
          },

          // legend: {
          //   labels: {
          //     fontSize: 25,
          //   },
          // },
        }}
        height={400}
      />
    </div>
  );
};

export default LineChart;
