import React, { useState, useEffect, forwardRef, Fragment } from "react";
import styled from "styled-components";
import { Typography, Radio } from "antd";
// import MaterialTable from "material-table";
import MUIDataTable from "mui-datatables";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useDispatch, useSelector } from "react-redux";
import { AllOrdersCrmDetails } from "../../../redux/slices/crmSlice";

const { Title } = Typography;
const Wrapper = styled.div`
  width: 100%;
`;
const BodyContainer = styled.div``;

const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    margin-right: 30px;
  }
`;

const AllOrdersCRM = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.crm.data.AllOrdersCrmDetails);

  const allOrdersCrm = data.map((o) => ({ ...o }));

  const [dateData, setDateData] = useState({
    start_date: "",
    end_date: "",
  });

  const onDateChange = (value, dateString) => {
    setDateData({
      ...dateData,
      start_date: dateString[0],
      end_date: dateString[1],
    });
  };
  useEffect(() => {
    dispatch(AllOrdersCrmDetails());
  }, []);

  const dateRangeCheck = async (e) => {
    e.preventDefault();
    let data = {};
    data.start_date = dateData.start_date;
    data.end_date = dateData.end_date;
    dispatch(AllOrdersCrmDetails(data));
    ////not dispatching yet rtk
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const tableColumns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "unique_id",
      label: "Unique ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "uid",
      label: "User ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "User Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ts_name",
      label: "Store/Task Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "camp_name",
      label: "Campaign Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "partner",
      label: "Partner",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "sale_amount",
      label: "Sale Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "click_id",
      label: "Click ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "order_id",
      label: "Order ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "payout",
      label: "Payout",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "point_post_payout",
      label: "Point Post Payout",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "conversion_date",
      label: "Conversion Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "action_date",
      label: "Action Date",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  return (
    <Fragment>
      <div className="my-3">
        <label>Date Range:--</label>
        <RangePicker onChange={onDateChange} />
        <button className="btn btn-primary ml-4" onClick={dateRangeCheck}>
          Submit
        </button>
      </div>
      {/* <MaterialTable
        title="Complete Orders Details.. "
        columns={tableColumns}
        icons={tableIcons}
        data={allOrdersCrm && allOrdersCrm}
        // options={{ search: true, actionsColumnIndex: -1, exportButton: true, allColumns: true,}}
        options={{
          actionsColumnIndex: -1,
          selection: true,
          showFirstLastPageButtons: true,
          pageSize: 10,
          padding: "dense",
          pageSizeOptions: [10, 20, allOrdersCrm ? allOrdersCrm.length : 100],
          exportButton: true,
        }}
      /> */}
      <MUIDataTable
        title={"Complete User Details"}
        rowsPerPage={10} // Ensure this is a number, not a string
        rowsPerPageOptions={[10, 20, 50, 100, 500]} // Options should be an array of numbers
        data={allOrdersCrm ?? []}
        columns={tableColumns}
        options={{
          filterType: "checkbox", // Optional: Define filter behavior
          selectableRows: "none", // Optional: Disable row selection
          responsive: "vertical", // Optional: Make the table responsive
        }}
      />
    </Fragment>
  );
};

export default AllOrdersCRM;
