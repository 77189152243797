import React, { useEffect, useState } from "react";
import { CCard, CCardBody, CCol, CRow, CWidgetStatsB } from "@coreui/react";
import WidgetsDropdown from "../widgets/WidgetsDropdown";
import { Pie, Doughnut, Line, Bar } from "react-chartjs-2";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

import dayjs from "dayjs";
import { addDays } from "date-fns";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardCompleteDetails,
  getPaymentStatus,
} from "../../redux/slices/dashboardSlice";
import { Box, Button } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { DateRangePicker } from "react-date-range"; //test
import LineChart from "../widgets/Chart/LineChart";

// Date converter
const dateConverter = (date) => {
  const arr = date.toString().split(" ");
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date_str =
    arr[3] + "-" + (months.indexOf(arr[1]) + 1).toString() + "-" + arr[2];
  return date_str;
};

const Dashboard = () => {
  const dispatch = useDispatch();
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement
  );

  const [showCalendar, setShowCalendar] = useState(false);
  const [dateData, setDateData] = useState({
    start_date: "",
    end_date: "",
  });

  // date range
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection",
    },
  ]);

  // Date range for API
  const [apiDate, setApiDate] = useState({
    from: dateConverter(dateRange[0].startDate),
    to: dateConverter(dateRange[0].endDate),
  });

  const state = useSelector((state) => state.dashboard.data);
  let balanceDataDashboard = state.balanceDataDashboard;
  let totalClaimsStatusDashbaord = state.totalClaimsStatusDashbaord;
  let mainDataDashboard = state.dashboardCompleteDetails;
  let redemptionHistoryDashboard = state.redemptionHistoryDashboard;
  let paymentStatusData = state.getPaymentStatus;
  let referedDataDashboard = state.referedDataDashboard;
  let signupUsersData = state.signUpUserDataDashboard;
  let totalTasksDataDashbaord = state.totalTasksDataDashbaord;

  //
  useEffect(() => {
    dispatch(dashboardCompleteDetails());
    dispatch(getPaymentStatus());
  }, []);

  // const past7Days = [...Array(7).keys()].map(index => {
  //   const date = new Date();
  //   date.setDate(date.getDate() - (index + 1));

  //   return date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear();
  // });

  const onChange = (value, dateString) => {
    setDateData({
      ...dateData,
      start_date: dateString[0],
      end_date: dateString[1],
    });
  };

  const dateRangeCheck = (e) => {
    e.preventDefault();
    let data = {};
    data.start_date = dateData.start_date;
    data.end_date = dateData.end_date;
    dispatch(dashboardCompleteDetails(data));
  };
  // Past 7 Days=========================================================================================
  let past7Days = [];
  mainDataDashboard &&
    mainDataDashboard.map((item) => {
      past7Days.push(item.graph_date);
    });

  // claims Data===================================================================================/
  let claims_status = [];
  let claims_count = [];
  totalClaimsStatusDashbaord &&
    totalClaimsStatusDashbaord.map((item) => {
      claims_status.push(item.status);
      claims_count.push(item.COUNT);
    });

  const claims_chart_data = {
    labels: claims_status,
    datasets: [
      {
        label: "claims",
        data: claims_count,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // redemption data==============================================================================================
  let redemption_status = [];
  let redemption_count = [];
  redemptionHistoryDashboard &&
    redemptionHistoryDashboard.map((item) => {
      redemption_status.push(item.status);
      redemption_count.push(item.count);
    });

  const redemption_chart_data = {
    labels: redemption_status,
    datasets: [
      {
        label: "Redemption",
        data: redemption_count,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // total task data==============================================================================

  let total_tasks_points = {};
  let total_tasks = [];
  totalTasksDataDashbaord &&
    totalTasksDataDashbaord.map((item) => {
      total_tasks_points[item.task_short_name] = item.points;
      total_tasks.push(item.points);
    });

  const total_task_data = {
    total_tasks,
    datasets: [
      {
        label: "Total points",
        data: total_tasks_points,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const total_task_options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Total Task Data",
      },
    },
  };

  // unique,clicks,orders data ================================================================================

  let unique_user_order_dashboard = {};
  let clicks_data_dashboard = {};
  let orders_data_dashboard = {};
  let total_cashback_Dashboard = {};
  mainDataDashboard &&
    mainDataDashboard.map((item) => {
      unique_user_order_dashboard[item.graph_date] = item.unique_orders;
      orders_data_dashboard[item.graph_date] = item.total_orders;
      total_cashback_Dashboard[item.graph_date] = item.total_cb;
      clicks_data_dashboard[item.graph_date] = item.click_count;
      // total_tasks_points[item.task_short_name] = item.points;
      // unique_user_order_dashboard.push(item.unique_orders)
      // clicks_data_dashboard.push(item.click_count)
      // orders_data_dashboard.push(item.total_orders)
      // total_cashback_Dashboard.push(item.total_cb)
    });

  let complete_graph_detaisl = {
    past7Days,
    datasets: [
      {
        label: "Unique User Orders",
        data: unique_user_order_dashboard,
        borderColor: "rgb(45,127,299)",
        backgroundColor: "rgba(45,127,299, 0.5)",
        yAxisID: "y",
      },
      {
        label: "Order Details",
        data: orders_data_dashboard,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        yAxisID: "y1",
      },
      {
        label: "Clicks Data Dashboard",
        data: clicks_data_dashboard,
        borderColor: "rgb(69, 111, 125)",
        backgroundColor: "rgba(69, 111, 125, 0.5)",
        yAxisID: "y2",
      },
      {
        label: "Total Cashback",
        data: total_cashback_Dashboard,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        yAxisID: "y3",
      },
    ],
  };

  const complete_details_options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: "Hyyzo Dashboard",
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  // Change date range
  const handleDateRangeChange = (item) => {
    setDateRange([
      {
        startDate: item.selection.startDate,
        endDate: item.selection.endDate,
        key: "selection",
      },
    ]);
    // setApiDate({
    //   from: dateConverter(item.selection.startDate),
    //   to: dateConverter(item.selection.endDate)
    // });
    let data = {};
    data.start_date = dateConverter(item.selection.startDate);
    data.end_date = dateConverter(item.selection.endDate);
    dispatch(dashboardCompleteDetails(data));
  };

  const handleToggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  // useEffect(() => {
  //   dispatch(dashboardCompleteDetails());
  // }, [signupUsersData]);

  return (
    <Box>
      {/* <div className="d-flex justify-content-between mb-4"> */}
      {/* <h6>
          Given Data Date Range BETWEEN {past7Days[0]} and{" "}
          {past7Days[past7Days.length - 1]}{" "}
        </h6> */}
      {/* <div>
          <RangePicker style={{ zIndex: 1059 }} onChange={onChange} />
          <button className="btn btn-primary ml-4" onClick={dateRangeCheck}>
            Submit
          </button>
        </div> */}
      <Button
        onClick={handleToggleCalendar}
        color="primary"
        variant="contained"
        startIcon={<CalendarMonthIcon />}
        sx={{ marginBottom: "5px" }}
      >
        <strong>Toggle Calendar</strong>
      </Button>
      {showCalendar && (
        <DateRangePicker
          ranges={dateRange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          direction="horizontal"
          onChange={(item) => handleDateRangeChange(item)}
        />
      )}

      {/* </div> */}
      <WidgetsDropdown
        signupUsersData={signupUsersData}
        paymentStatusData={paymentStatusData}
        balanceDataDashboard={balanceDataDashboard}
      />

      <CCard className="mb-5">
        <CCardBody>
          <div className="graph" style={{ height: "100%", width: "100%" }}>
            {/* <Line
              className="width_60 height_60 m-auto"
              options={complete_details_options}
              data={complete_graph_detaisl}
            /> */}
            <LineChart
              data={{
                datasets: [
                  {
                    label: "Unique User Orders",
                    data: unique_user_order_dashboard,
                    backgroundColor: [
                      "rgba(255, 99, 132, 0.2)",
                      // "rgba(54, 162, 235, 0.2)",
                      // "rgba(255, 206, 86, 0.2)",
                      // "rgba(75, 192, 192, 0.2)",
                      // "rgba(153, 102, 255, 0.2)",
                      // "rgba(255, 159, 64, 0.2)",
                    ],
                    borderColor: [
                      "rgba(255, 99, 132, 1)",
                      // "rgba(54, 162, 235, 1)",
                      // "rgba(255, 206, 86, 1)",
                      // "rgba(75, 192, 192, 1)",
                      // "rgba(153, 102, 255, 1)",
                      // "rgba(255, 159, 64, 1)",
                    ],
                    borderWidth: 2,
                    yAxisID: "y1",
                  },
                  {
                    label: "Order Details",
                    data: orders_data_dashboard,
                    backgroundColor: [
                      // "rgba(255, 99, 132, 0.2)",
                      "rgba(54, 162, 235, 0.2)",
                      // "rgba(255, 206, 86, 0.2)",
                      // "rgba(75, 192, 192, 0.2)",
                      // "rgba(153, 102, 255, 0.2)",
                      // "rgba(255, 159, 64, 0.2)",
                    ],
                    borderColor: [
                      // "rgba(255, 99, 132, 1)",
                      "rgba(54, 162, 235, 1)",
                      // "rgba(255, 206, 86, 1)",
                      // "rgba(75, 192, 192, 1)",
                      // "rgba(153, 102, 255, 1)",
                      // "rgba(255, 159, 64, 1)",
                    ],
                    borderWidth: 2,
                    yAxisID: "y2",
                  },
                  {
                    label: "Clicks Data Dashboard",
                    data: clicks_data_dashboard,
                    backgroundColor: [
                      // "rgba(255, 99, 132, 0.2)",
                      // "rgba(54, 162, 235, 0.2)",
                      "rgba(255, 206, 86, 0.2)",
                      // "rgba(75, 192, 192, 0.2)",
                      // "rgba(153, 102, 255, 0.2)",
                      // "rgba(255, 159, 64, 0.2)",
                    ],
                    borderColor: [
                      // "rgba(255, 99, 132, 1)",
                      // "rgba(54, 162, 235, 1)",
                      "rgba(255, 206, 86, 1)",
                      // "rgba(75, 192, 192, 1)",
                      // "rgba(153, 102, 255, 1)",
                      // "rgba(255, 159, 64, 1)",
                    ],
                    borderWidth: 2,
                    yAxisID: "y3",
                  },
                  {
                    label: "Total Cashback",
                    data: total_cashback_Dashboard,
                    backgroundColor: [
                      // "rgba(255, 99, 132, 0.2)",
                      // "rgba(54, 162, 235, 0.2)",
                      // "rgba(255, 206, 86, 0.2)",
                      "rgba(75, 192, 192, 0.2)",
                      // "rgba(153, 102, 255, 0.2)",
                      // "rgba(255, 159, 64, 0.2)",
                    ],
                    borderColor: [
                      // "rgba(255, 99, 132, 1)",
                      // "rgba(54, 162, 235, 1)",
                      // "rgba(255, 206, 86, 1)",
                      "rgba(75, 192, 192, 1)",
                      // "rgba(153, 102, 255, 1)",
                      // "rgba(255, 159, 64, 1)",
                    ],
                    borderWidth: 2,
                    yAxisID: "y4",
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y1: {
                    type: "linear",
                    display: true,
                    position: "left",
                    ticks: {
                      color: "rgba(255, 99, 132, 1)", // Color of y1 axis labels
                    },
                  },
                  y2: {
                    type: "linear",
                    display: true,
                    position: "left",

                    grid: {
                      drawOnChartArea: false,
                    },
                    ticks: {
                      color: "rgba(54, 162, 235, 1)", // Color of y2 axis labels
                    },
                  },
                  y3: {
                    type: "linear",
                    display: true,
                    position: "right",

                    grid: {
                      drawOnChartArea: false,
                    },
                    ticks: {
                      color: "rgba(255, 206, 86, 1)", // Color of y3 axis labels
                    },
                  },
                  y4: {
                    type: "linear",
                    display: true,
                    position: "right",

                    grid: {
                      drawOnChartArea: false,
                    },
                    ticks: {
                      color: "rgba(75, 192, 192, 1)", // Color of y4 axis labels
                    },
                  },
                },
                plugins: {
                  legend: {
                    labels: {
                      font: {
                        size: 15,
                      },
                    },
                  },
                },
              }}
            />
          </div>
        </CCardBody>
      </CCard>

      <CRow>
        <CCol xs={12} md={3} xl={3}>
          <Pie className="width_75 height_75 m-auto" data={claims_chart_data} />
          <h6 className="text-center mt-3">Claims chart Data</h6>
        </CCol>
        <CCol xs={12} md={3} xl={3}>
          <Doughnut
            className="width_75 height_75 m-auto"
            data={redemption_chart_data}
          />
          <h6 className="text-center mt-3">Redemption Chart Data</h6>
        </CCol>
        <CCol xs={12} md={3} xl={3}>
          <CWidgetStatsB
            className="mb-3"
            color="success"
            progress={{
              value:
                referedDataDashboard &&
                referedDataDashboard.length &&
                referedDataDashboard[0].referredTo,
            }}
            title="Referred To"
            value={`${
              referedDataDashboard &&
              referedDataDashboard.length &&
              referedDataDashboard[0].referredTo
            }`}
          />
          <h6 className="text-center mt-3">Referral Data</h6>
        </CCol>
        <CCol xs={12} md={3} xl={3}>
          <Bar options={total_task_options} data={total_task_data} />
          <h6 className="text-center mt-3">Toatl Task Data</h6>
        </CCol>
      </CRow>
    </Box>
  );
};

export default Dashboard;
