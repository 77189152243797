import React, { Fragment, useState, useEffect, forwardRef } from "react";
import { DatePicker, Modal } from "antd";
const { RangePicker } = DatePicker;
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import {
  userLogClicksDetails,
  newUsersDetails,
} from "../../redux/slices/userDetailsSlice";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";

const UserDetails = () => {
  const dispatch = useDispatch();
  const { newUsersDetailsData, userLogClicksDetailsData } = useSelector(
    (state) => ({ ...state.userDetails.data })
  );
  const [newUserData, setNewUserData] = useState({
    uid: "",
  });
  const [dateData, setDateData] = useState({
    start_date: "",
    end_date: "",
  });
  const [visible, setVisible] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(newUsersDetails());
  }, []);

  const onDateChange = (value, dateString) => {
    setDateData({
      ...dateData,
      start_date: dateString[0],
      end_date: dateString[1],
    });
  };
  const dateRangeCheck = (e) => {
    e.preventDefault();
    let data = {};
    data.start_date = dateData.start_date;
    data.end_date = dateData.end_date;
    dispatch(newUsersDetails(data));
  };

  // const handleModal = (e) => {
  //     setNewUserData({ ...newUserData, uid: e.target.textContent })
  //     if (newUsersDetailsData.length > 0) {
  //         for (var i = 0; i < newUsersDetailsData.length; i++) {
  //             if (e.target.textContent == newUsersDetailsData[i].uid) {
  //                 setNewUserData({ ...newUserData, uid: newUsersDetailsData[i].uid });
  //                 let data = {};
  //                 data.uid = newUsersDetailsData[i].uid;
  //                 dispatch(userLogClicksDetails(data));
  //             }
  //         }
  //     }
  //     setVisible(true);
  // };

  const handleDialog = (e) => {
    setOpen(true);
    setNewUserData({ ...newUserData, uid: e.target.textContent });
    if (newUsersDetailsData.length > 0) {
      for (var i = 0; i < newUsersDetailsData.length; i++) {
        if (e.target.textContent == newUsersDetailsData[i].uid) {
          setNewUserData({ ...newUserData, uid: newUsersDetailsData[i].uid });
          let data = {};
          data.uid = newUsersDetailsData[i].uid;
          dispatch(userLogClicksDetails(data));
        }
      }
    }
  };

  const columns = [
    {
      name: "uid",
      label: "UID",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (rowData) => {
          return (
            <>
              <p onClick={handleDialog} style={{ cursor: "pointer" }}>
                {rowData}
              </p>
            </>
          );
        },
      },
    },
    {
      name: "name",
      label: "User Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "mobile",
      label: "Mobile",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "referredBy",
      label: "Referred By",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ref_email",
      label: "Referred Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ref_name",
      label: "Referred Name",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const dialogcolumns = [
    {
      name: "uid",
      label: "User_id",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "url",
      label: "Url",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "time",
      label: "Time",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const dialogoptions = {
    filterType: "uid",
  };

  return (
    <Fragment>
      <div className="my-3">
        <label>Date Range:-- &nbsp;&nbsp;&nbsp; </label>{" "}
        {/*&nbsp; => for blank spaces */}
        <RangePicker onChange={onDateChange} />
        <button className="btn btn-primary ml-4" onClick={dateRangeCheck}>
          Submit
        </button>
      </div>
      <MUIDataTable
        title={"New User SignUp "}
        data={newUsersDetailsData ?? []}
        columns={columns}
      />
      <Dialog open={open}>
        <DialogTitle>
          <b>Uesr Click Data</b>
        </DialogTitle>
        <Divider sx={{ borderColor: "black" }} />
        <DialogContent>
          <Box mt={2} className="d-flex align-items-center gap-2">
            <Typography>
              <MUIDataTable
                title={"Show Log Clicks  (search by User id)"}
                data={
                  userLogClicksDetailsData.data && userLogClicksDetailsData.data
                }
                columns={dialogcolumns}
                options={dialogoptions}
              />
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions p={3}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setOpen(false)}
          >
            CLose
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default UserDetails;
